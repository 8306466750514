
import { defineComponent, ref, inject, onMounted } from 'vue';
import { Select, Radio } from '@/components/input-elements';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Apollo, Notify } from '@/core/services';
import { GET_CUSTOMER_GROUP } from '@/modules/customer/customer-groups/graphql/Queries';
import InnerLoader from '../../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'add new shipping method',
    components: {
        Radio,
        Select,
        InnerLoader
    },

    setup(props, context) {
        const emitter: any = inject('emitter');
        const customerGroupFormRef = ref<null | HTMLFormElement>(null);
        const customerGroups = ref([]) as Record<any, any>;
        const customerGroupShippingLabel = ref([]) as Record<any, any>;
        const store = useStore();
        const i18n = useI18n();
        const optionRule = ref([]) as Record<any, any>;
        const cartRuleOption = ref([]) as Record<any, any>;
        const loader = ref(false);
        const customerGroupRules = ref([]) as Record<any, any>;
        const customerGroupRule = ref([]) as Record<any, any>;
        const customerGroupCartOption = ref([]) as Record<any, any>;

        // Given Shipping Form Interface
        const customerGroupFormData = ref({
            rule: [],
            cartRule: [],
            customer_group_cost_no_rule: [],
            customer_group_tax_no_rule: [],
            customer_groups: [],
            customer_group_cart_rules: [],
            customer_group_cart_rules2: [],
            customer_group_cart_cost: [],
            customer_group_cart_cost2: [],
            customer_group_tax: [],
            customer_group_tax2: [],
            cartRulesData: {},
            noRuleCost: {}
        }) as any;

        cartRuleOption.value = [
            {
                label: 'message.WEIGHT message.RULE',
                value: 0,
                default: 0
            },
            {
                label: 'message.TOTAL message.RULE',
                value: 1,
                default: 0
            }
        ];

        optionRule.value = [
            {
                label: 'message.NO message.RULE',
                value: 0
            },
            {
                label: 'message.RULE',
                value: 1
            }
        ];

        const getCustomerGroupShippingRuleLabel = e => {
            e.forEach((element, index) => {
                if (element == 1) {
                    customerGroupShippingLabel.value[index] = `${i18n.t('message.TOTAL')} ${i18n.t('message.RULE')}`;
                } else {
                    customerGroupShippingLabel.value[index] = `${i18n.t('message.WEIGHT')} ${i18n.t('message.RULE')}`;
                }
            });
        };

        const getCustomerGroups = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_CUSTOMER_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                customerGroups.value = [];
                data.customer_groups.data.forEach(element => {
                    customerGroups.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
                loader.value = false;
            });
        };

        onMounted(() => {
            getCustomerGroups();
        });

        const customerGroup = (groups: Record<any, any>) => {
            if (Object.entries(groups).length != 0) {
                customerGroupRules.value = [];
                groups.forEach(element => {
                    const customers = customerGroups.value.find(ele => ele.value == element);
                    customerGroupRules.value.push({
                        relation: 'customer-group',
                        data: customers
                    });
                });
            } else {
                customerGroupRules.value = [];
            }
        };

        const validateForm = () => {
            customerGroupFormRef.value?.validate(valid => {
                if (valid) {
                    const shippingCustomerGroupName = [] as Record<any, any>;
                    for (let i = 0; i < customerGroupRules.value.length; i++) {
                        if (customerGroupRule.value[customerGroupRules.value[i].data.value] == 1) {
                            if (
                                typeof customerGroupFormData.value.customer_group_cart_rules[customerGroupRules.value[i].data.value] === 'undefined' ||
                                customerGroupFormData.value.customer_group_cart_rules[customerGroupRules.value[i].data.value] == '' ||
                                typeof customerGroupFormData.value.customer_group_cart_rules2[customerGroupRules.value[i].data.value] === 'undefined' ||
                                customerGroupFormData.value.customer_group_cart_rules2[customerGroupRules.value[i].data.value] == '' ||
                                typeof customerGroupFormData.value.customer_group_cart_cost[customerGroupRules.value[i].data.value] === 'undefined' ||
                                customerGroupFormData.value.customer_group_cart_cost[customerGroupRules.value[i].data.value] == '' ||
                                typeof customerGroupFormData.value.customer_group_cart_cost2[customerGroupRules.value[i].data.value] === 'undefined' ||
                                customerGroupFormData.value.customer_group_cart_cost2[customerGroupRules.value[i].data.value] == ''
                            ) {
                                shippingCustomerGroupName.push(customerGroupRules.value[i].data.label);
                            }
                        } else if (
                            typeof customerGroupFormData.value.customer_group_cost_no_rule[customerGroupRules.value[i].data.value] === 'undefined' ||
                            customerGroupFormData.value.customer_group_cost_no_rule[customerGroupRules.value[i].data.value] == ''
                        ) {
                            shippingCustomerGroupName.push(customerGroupRules.value[i].data.label);
                        }
                    }
                    if (shippingCustomerGroupName.length > 0) {
                        Notify.error(`${i18n.t('message.ENTER_RULES_AND_PRICE_FOR_SELECTED')} ${shippingCustomerGroupName.join(' , ')} ${i18n.t('message.SHIPPING')}`);
                        return;
                    }

                    const cartRulesData = {
                        customer_groups: {
                            greater_condition: {
                                rules: customerGroupFormData.value.customer_group_cart_rules,
                                cost: customerGroupFormData.value.customer_group_cart_cost,
                                tax: customerGroupFormData.value.customer_group_tax
                            },
                            less_condition: {
                                rules: customerGroupFormData.value.customer_group_cart_rules2,
                                cost: customerGroupFormData.value.customer_group_cart_cost2,
                                tax: customerGroupFormData.value.customer_group_tax2
                            }
                        }
                    };

                    customerGroupFormData.value.cartRulesData = cartRulesData;

                    const noRuleCost = {
                        customer_group_cost: customerGroupFormData.value.customer_group_cost_no_rule,
                        customer_group_tax: customerGroupFormData.value.customer_group_tax_no_rule
                    };

                    customerGroupFormData.value.noRuleCost = noRuleCost;
                    customerGroupFormData.value.rule = customerGroupRule.value;
                    customerGroupFormData.value.cartRule = customerGroupCartOption.value;

                    context.emit('store', 'customerGroupFormData', customerGroupFormData.value);
                    return true;
                }

                return false;
            });
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            customerGroupFormRef.value?.resetFields();
        };

        const editShippingMethodValue = (shippingData: Record<any, any>) => {
            resetForm();
            if (shippingData.customer_groups.length > 0) {
                shippingData.customer_groups.forEach(element => {
                    customerGroupFormData.value.customer_groups.push(element.id);
                    customerGroupRules.value.push({
                        relation: 'customer-group',
                        data: { label: element.name, value: element.id }
                    });
                    shippingData.shipping_rule.forEach(ele => {
                        if (ele.relation == 'customer-group' && ele.relation_id == element.id) {
                            switch (ele.condition) {
                                case '==':
                                    customerGroupRule.value[element.id] = 0;
                                    customerGroupFormData.value.customer_group_cost_no_rule[element.id] = ele.shipping_cost.cost;
                                    customerGroupFormData.value.customer_group_tax_no_rule[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    break;
                                case '>=':
                                    customerGroupRule.value[element.id] = 1;
                                    customerGroupCartOption.value[element.id] = ele.rule == 'total' ? 1 : 0;
                                    customerGroupShippingLabel.value[element.id] = customerGroupCartOption.value[element.id] == 1 ? 'Total Rule' : 'Weight Rule';
                                    customerGroupFormData.value.customer_group_cart_cost[element.id] = ele.shipping_cost.cost;
                                    customerGroupFormData.value.customer_group_tax[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    customerGroupFormData.value.customer_group_cart_rules[element.id] = ele.cart_rule_value;
                                    break;
                                case '<':
                                    customerGroupFormData.value.customer_group_cart_cost2[element.id] = ele.shipping_cost.cost;
                                    customerGroupFormData.value.customer_group_tax2[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    customerGroupFormData.value.customer_group_cart_rules2[element.id] = ele.cart_rule_value;
                                    break;
                            }
                        }
                    });
                });
            }
        };

        return {
            customerGroupFormData,
            customerGroups,
            optionRule,
            cartRuleOption,
            customerGroupFormRef,
            customerGroupRules,
            customerGroupRule,
            customerGroupCartOption,
            customerGroupShippingLabel,
            loader,
            editShippingMethodValue,
            validateForm,
            getCustomerGroupShippingRuleLabel,
            customerGroup
        };
    }
});
