
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Select, Radio } from '@/components/input-elements';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Notify } from '@/core/services';

export default defineComponent({
    name: 'add new shipping method',
    components: {
        Radio,
        Select
    },

    setup(props, context) {
        const emitter: any = inject('emitter');
        const siteFormRef = ref<null | HTMLFormElement>(null);
        const sites = ref([]) as Record<any, any>;
        const store = useStore();
        const i18n = useI18n();
        const rule = ref([]) as Record<any, any>;
        const optionRule = ref([]) as Record<any, any>;
        const cartRule = ref([]) as Record<any, any>;
        const cartRuleOption = ref([]) as Record<any, any>;
        const siteRules = ref([]) as Record<any, any>;
        const shippingLabel = ref([]) as Record<any, any>;

        // Given Shipping Form Interface
        const sitesFormData = ref({
            rule: [],
            cartRule: [],
            site_cost_no_rule: [],
            site_tax_no_rule: [],
            sites: [],
            site_cart_rules: [],
            site_cart_rules2: [],
            site_cart_cost: [],
            site_cart_cost2: [],
            site_tax: [],
            site_tax2: [],
            cartRulesData: {},
            noRuleCost: {}
        }) as any;

        cartRuleOption.value = [
            {
                label: 'message.WEIGHT message.RULE',
                value: 0,
                default: 0
            },
            {
                label: 'message.TOTAL message.RULE',
                value: 1,
                default: 0
            }
        ];

        optionRule.value = [
            {
                label: 'message.NO message.RULE',
                value: 0
            },
            {
                label: 'message.RULE',
                value: 1
            }
        ];

        watchEffect(() => {
            sites.value = [];
            const getSites = store.getters.getAllSites.data.sites;
            getSites.forEach((element, index) => {
                sites.value.push({
                    label: element.name,
                    value: element.id,
                    index: index
                });
            });
        });

        const getSiteRules = (siteRule: Record<any, any>) => {
            if (Object.entries(sites).length != 0) {
                siteRules.value = [];
                siteRule.forEach(element => {
                    const site = sites.value.find(ele => ele.value == element);
                    siteRules.value.push({
                        relation: 'sites',
                        data: site
                    });
                });
            } else {
                siteRules.value = [];
            }
        };

        const getShippingRuleLabel = e => {
            e.forEach((element, index) => {
                if (element == 1) {
                    shippingLabel.value[index] = `${i18n.t('message.TOTAL')} ${i18n.t('message.RULE')}`;
                } else {
                    shippingLabel.value[index] = `${i18n.t('message.WEIGHT')} ${i18n.t('message.RULE')}`;
                }
            });
        };

        const validateForm = () => {
            siteFormRef.value?.validate(valid => {
                if (valid) {
                    const name = [] as Record<any, any>;
                    for (let i = 0; i < siteRules.value.length; i++) {
                        if (rule.value[siteRules.value[i].data.value] == 1) {
                            if (
                                typeof sitesFormData.value.site_cart_rules[siteRules.value[i].data.value] === 'undefined' ||
                                sitesFormData.value.site_cart_rules[siteRules.value[i].data.value] == '' ||
                                typeof sitesFormData.value.site_cart_rules2[siteRules.value[i].data.value] === 'undefined' ||
                                sitesFormData.value.site_cart_rules2[siteRules.value[i].data.value] == '' ||
                                typeof sitesFormData.value.site_cart_cost[siteRules.value[i].data.value] === 'undefined' ||
                                sitesFormData.value.site_cart_cost[siteRules.value[i].data.value] == '' ||
                                typeof sitesFormData.value.site_cart_cost2[siteRules.value[i].data.value] === 'undefined' ||
                                sitesFormData.value.site_cart_cost2[siteRules.value[i].data.value] == ''
                            ) {
                                name.push(siteRules.value[i].data.label);
                            }
                        } else if (
                            typeof sitesFormData.value.site_cost_no_rule[siteRules.value[i].data.value] === 'undefined' ||
                            sitesFormData.value.site_cost_no_rule[siteRules.value[i].data.value] == ''
                        ) {
                            name.push(siteRules.value[i].data.label);
                        }
                    }

                    if (name.length > 0) {
                        Notify.error(`${i18n.t('message.ENTER_RULES_AND_PRICE_FOR_SELECTED')} ${name.join(' , ')} ${i18n.t('message.SHIPPING')}`);
                        return;
                    }

                    const cartRulesData = {
                        sites: {
                            greater_condition: {
                                rules: sitesFormData.value.site_cart_rules,
                                cost: sitesFormData.value.site_cart_cost,
                                tax: sitesFormData.value.site_tax
                            },
                            less_condition: {
                                rules: sitesFormData.value.site_cart_rules2,
                                cost: sitesFormData.value.site_cart_cost2,
                                tax: sitesFormData.value.site_tax2
                            }
                        }
                    };

                    sitesFormData.value.cartRulesData = cartRulesData;
                    const noRuleCost = {
                        site_cost: sitesFormData.value.site_cost_no_rule,
                        site_tax: sitesFormData.value.site_tax_no_rule
                    };

                    sitesFormData.value.noRuleCost = noRuleCost;
                    sitesFormData.value.rule = rule.value;
                    sitesFormData.value.cartRule = cartRule.value;

                    context.emit('store', 'sitesFormData', sitesFormData.value);
                    return true;
                }

                return false;
            });
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            siteFormRef.value?.resetFields();
        };

        const editShippingMethodValue = (shippingData: Record<any, any>) => {
            resetForm();
            if (shippingData.sites.length > 0) {
                shippingData.sites.forEach(element => {
                    sitesFormData.value.sites.push(element.id);
                    siteRules.value.push({
                        relation: 'sites',
                        data: { label: element.name, value: element.id }
                    });

                    shippingData.shipping_rule.forEach(ele => {
                        if (ele.relation == 'site' && ele.relation_id == element.id) {
                            switch (ele.condition) {
                                case '==':
                                    rule.value[element.id] = 0;
                                    sitesFormData.value.site_cost_no_rule[element.id] = ele.shipping_cost.cost;
                                    sitesFormData.value.site_tax_no_rule[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    break;
                                case '>=':
                                    rule.value[element.id] = 1;
                                    cartRule.value[element.id] = ele.rule == 'total' ? 1 : 0;
                                    sitesFormData.value.site_cart_cost[element.id] = ele.shipping_cost.cost;
                                    sitesFormData.value.site_tax[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    sitesFormData.value.site_cart_rules[element.id] = ele.cart_rule_value;
                                    break;
                                case '<':
                                    sitesFormData.value.site_cart_cost2[element.id] = ele.shipping_cost.cost;
                                    sitesFormData.value.site_tax2[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    sitesFormData.value.site_cart_rules2[element.id] = ele.cart_rule_value;
                                    break;
                            }
                        }
                    });
                });
            }
        };

        return {
            sitesFormData,
            sites,
            optionRule,
            rule,
            cartRule,
            cartRuleOption,
            shippingLabel,
            siteRules,
            siteFormRef,
            editShippingMethodValue,
            validateForm,
            getShippingRuleLabel,
            getSiteRules
        };
    }
});
