
import { defineComponent, ref, inject, onMounted } from "vue";
import { Select, Radio } from "@/components/input-elements";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Apollo, Notify } from "@/core/services";
import { GET_CUSTOMER } from "@/modules/customer/customers/graphql/Queries";
import InnerLoader from "../../../../../components/InnerLoader.vue";

export default defineComponent({
	name: "add new shipping method",
	components: {
		Radio,
		Select,
		InnerLoader,
	},

	setup(props, context) {
		const emitter: any = inject("emitter");
		const customerFormRef = ref<null | HTMLFormElement>(null);
		const store = useStore();
		const i18n = useI18n();
		const optionRule = ref([]) as Record<any, any>;
		const cartRuleOption = ref([]) as Record<any, any>;
		const loader = ref(false);
		const customers = ref([]) as Record<any, any>;
		const customerRules = ref([]) as Record<any, any>;
		const customerRule = ref([]) as Record<any, any>;
		const customerCartOption = ref([]) as Record<any, any>;
		const customerShippingLabel = ref([]) as Record<any, any>;

		// Given Shipping Form Interface
		const customersFormData = ref({
			rule: [],
			cartRule: [],
			customers: [],
			customer_cart_rules: [],
			customer_cart_rules2: [],
			customer_cart_cost: [],
			customer_cart_cost2: [],
			customer_tax: [],
			customer_tax2: [],
			customer_cost_no_rule: [],
			customer_tax_no_rule: [],
			cartRulesData: {},
			noRuleCost: {},
		}) as any;

		cartRuleOption.value = [
			{
				label: "message.WEIGHT message.RULE",
				value: 0,
				default: 0,
			},
			{
				label: "message.TOTAL message.RULE",
				value: 1,
				default: 0,
			},
		];

		optionRule.value = [
			{
				label: "message.NO message.RULE",
				value: 0,
			},
			{
				label: "message.RULE",
				value: 1,
			},
		];

		const getCustomers = () => {
			loader.value = true;
			Apollo.watchQuery({
				query: GET_CUSTOMER,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				customers.value = [];
				data.customers.data.forEach((element) => {
					customers.value.push({
						label: element.user.name,
						value: element.id,
					});
				});
				loader.value = false;
			});
		};

		onMounted(() => {
			getCustomers();
		});

		const onChangeCustomer = (customerIds: Record<any, any>) => {
			if (Object.entries(customerIds).length != 0) {
				customerRules.value = [];
				customerIds.forEach((element) => {
					const customer = customers.value.find((ele) => ele.value == element);
					customerRules.value.push({
						relation: "customer",
						data: customer,
					});
				});
			} else {
				customerRules.value = [];
			}
		};

		const getCustomerShippingRuleLabel = (e) => {
			e.forEach((element, index) => {
				if (element == 1) {
					customerShippingLabel.value[index] = `${i18n.t("message.TOTAL")} ${i18n.t("message.RULE")}`;
				} else {
					customerShippingLabel.value[index] = `${i18n.t("message.WEIGHT")} ${i18n.t("message.RULE")}`;
				}
			});
		};

		const validateForm = () => {
			customerFormRef.value?.validate((valid) => {
				if (valid) {
					const customersName = [] as Record<any, any>;
					for (let i = 0; i < customerRules.value.length; i++) {
						if (customerRule.value[customerRules.value[i].data.value] == 1) {
							if (
								typeof customersFormData.value.customer_cart_rules[customerRules.value[i].data.value] === "undefined" ||
								customersFormData.value.customer_cart_rules[customerRules.value[i].data.value] == "" ||
								typeof customersFormData.value.customer_cart_rules2[customerRules.value[i].data.value] === "undefined" ||
								customersFormData.value.customer_cart_rules2[customerRules.value[i].data.value] == "" ||
								typeof customersFormData.value.customer_cart_cost[customerRules.value[i].data.value] === "undefined" ||
								customersFormData.value.customer_cart_cost[customerRules.value[i].data.value] == "" ||
								typeof customersFormData.value.customer_cart_cost2[customerRules.value[i].data.value] === "undefined" ||
								customersFormData.value.customer_cart_cost2[customerRules.value[i].data.value] == ""
							) {
								customersName.push(customerRules.value[i].data.label);
							}
						} else if (
							typeof customersFormData.value.customer_cost_no_rule[customerRules.value[i].data.value] === "undefined" ||
							customersFormData.value.customer_cost_no_rule[customerRules.value[i].data.value] == ""
						) {
							customersName.push(customerRules.value[i].data.label);
						}
					}

					if (customersName.length > 0) {
						Notify.error(`${i18n.t("message.ENTER_RULES_AND_PRICE_FOR_SELECTED")} ${customersName.join(" , ")} ${i18n.t("message.SHIPPING")}`);
						return;
					}

					const cartRulesData = {
						customers: {
							greater_condition: {
								rules: customersFormData.value.customer_cart_rules,
								cost: customersFormData.value.customer_cart_cost,
								tax: customersFormData.value.customer_tax,
							},
							less_condition: {
								rules: customersFormData.value.customer_cart_rules2,
								cost: customersFormData.value.customer_cart_cost2,
								tax: customersFormData.value.customer_tax2,
							},
						},
					};

					customersFormData.value.cartRulesData = cartRulesData;

					const noRuleCost = {
						customer_cost: customersFormData.value.customer_cost_no_rule,
						customer_tax: customersFormData.value.customer_tax_no_rule,
					};

					customersFormData.value.noRuleCost = noRuleCost;
					customersFormData.value.rule = customerRule.value;
					customersFormData.value.cartRule = customerCartOption.value;

					context.emit("store", "customersFormData", customersFormData.value);
					return true;
				}

				return false;
			});
		};

		const resetForm = () => {
			emitter.emit("clearInput");
			customerFormRef.value?.resetFields();
		};

		const editShippingMethodValue = (shippingData: Record<any, any>) => {
			resetForm();
			if (shippingData.customers.length > 0) {
				shippingData.customers.forEach((element) => {
					customersFormData.value.customers.push(element.id);
					customerRules.value.push({
						relation: "customer",
						data: { label: element.user.name, value: element.id },
					});
					shippingData.shipping_rule.forEach((ele) => {
						if (ele.relation == "customer" && ele.relation_id == element.id) {
							switch (ele.condition) {
								case "==":
									customerRule.value[element.id] = 0;
									customersFormData.value.customer_cost_no_rule[element.id] = ele.shipping_cost.cost;
									customersFormData.value.customer_tax_no_rule[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
									break;
								case ">=":
									customerRule.value[element.id] = 1;
									customerCartOption.value[element.id] = ele.rule == "total" ? 1 : 0;
									customerShippingLabel.value[element.id] = customerCartOption.value[element.id] == 1 ? "Total Rule" : "Weight Rule";
									customersFormData.value.customer_cart_cost[element.id] = ele.shipping_cost.cost;
									customersFormData.value.customer_tax[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
									customersFormData.value.customer_cart_rules[element.id] = ele.cart_rule_value;
									break;
								case "<":
									customersFormData.value.customer_cart_cost2[element.id] = ele.shipping_cost.cost;
									customersFormData.value.customer_tax2[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
									customersFormData.value.customer_cart_rules2[element.id] = ele.cart_rule_value;
									break;
							}
						}
					});
				});
			}
		};

		return {
			customersFormData,
			optionRule,
			cartRuleOption,
			customerFormRef,
			customers,
			customerRules,
			customerRule,
			customerCartOption,
			customerShippingLabel,
			loader,
			getCustomerShippingRuleLabel,
			editShippingMethodValue,
			validateForm,
			onChangeCustomer,
		};
	},
});
